import React, { useEffect } from "react"
import Layer from "../components/Layer"
import Seo from "../components/Seo"
import "../css/style.css"
import da from "../js/dynamic_adapt"
import { spollers } from "../js/functions"
import Retfees from "../components/Retfees"

const Retailers = () => {
  useEffect(() => {
		da.init()
		spollers()
	}, [])
  return (
    <>
      <Seo title="Retailers fees"/>
      <Layer>
        <main className="fees-page__container">
        <Retfees />
        </main>
      </Layer>
    </>
  )
}

export default Retailers
