// extracted by mini-css-extract-plugin
export var c0 = "retailers-module--c0--LILaG";
export var c1 = "retailers-module--c1--4bqLq";
export var c10 = "retailers-module--c10--EwtZM";
export var c11 = "retailers-module--c11--JVusM";
export var c12 = "retailers-module--c12--c63iF";
export var c13 = "retailers-module--c13--DK0zj";
export var c14 = "retailers-module--c14--x0TcM";
export var c15 = "retailers-module--c15--h-h1w";
export var c16 = "retailers-module--c16--gZq6O";
export var c17 = "retailers-module--c17--aIYIW";
export var c18 = "retailers-module--c18--Vafaz";
export var c19 = "retailers-module--c19--MaQSA";
export var c2 = "retailers-module--c2--YV7-D";
export var c20 = "retailers-module--c20--oklRg";
export var c21 = "retailers-module--c21--LT1h3";
export var c22 = "retailers-module--c22--EYKYh";
export var c23 = "retailers-module--c23--g9Z5k";
export var c24 = "retailers-module--c24--3vnA7";
export var c25 = "retailers-module--c25--AMvC2";
export var c26 = "retailers-module--c26--1+bPz";
export var c27 = "retailers-module--c27--2CEoJ";
export var c28 = "retailers-module--c28--Hn++z";
export var c29 = "retailers-module--c29--pCw+-";
export var c3 = "retailers-module--c3--o9PKV";
export var c30 = "retailers-module--c30--TeUDv";
export var c31 = "retailers-module--c31--L+C+k";
export var c32 = "retailers-module--c32--tqPUD";
export var c33 = "retailers-module--c33--N+ucK";
export var c34 = "retailers-module--c34--v+Ma4";
export var c35 = "retailers-module--c35--jh0v1";
export var c4 = "retailers-module--c4--cDG2P";
export var c5 = "retailers-module--c5--Xr86l";
export var c6 = "retailers-module--c6--sL+1X";
export var c7 = "retailers-module--c7--eiDBm";
export var c8 = "retailers-module--c8--RH503";
export var c9 = "retailers-module--c9--pJDkz";
export var fee_header = "retailers-module--fee_header--Ev8ki";
export var fees_content = "retailers-module--fees_content--5nsRS";
export var subtitle = "retailers-module--subtitle--pr1f2";
export var title = "retailers-module--title--HItbZ";
export var ul_fees = "retailers-module--ul_fees--vKonI";